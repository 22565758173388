import {
	FC,
	useEffect,
	useMemo,
	useState,
	MouseEvent,
	useCallback,
	FocusEvent,
	ChangeEvent,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getSwapAssetList,
	getSwapPairList,
	getAddTokenList,
	getPairBalance,
} from 'redux/reducers/swap/selectors';
import { getSwapAssetsRequest, getSwapPairsRequest } from 'redux/reducers/swap/reducer';
import { getSwapAssets } from 'redux/reducers/swap/selectors';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { NetworkModal } from 'components/Exchange/NetworkModal';
import { ISwapTokenUnsafe } from 'redux/reducers/swap/types';
import { feeChecker } from 'services/utils/feeChecker';
import { noExponent } from 'services/utils/noExponent';
import { getTokenStatus } from 'redux/reducers/coins/selectors';
import { addLiquidity } from 'services/utils/liquidity';
import { IApiSwapPair } from 'services/api/swap/types';
import { ApproveTokens } from 'services/utils/approve';
import { useLocation } from 'react-router';
import { ConnectWalletButton } from 'ui/ConnectWalletButton';
import AbiRouter from 'config/abi/GatoswapRouter.json';
import AbiFee from 'config/abi/FeeController.json';
/* eslint-disable */
import { usePrevious } from 'hooks/usePrevious';

import {
	clearTokenBalances,
	getTokenBalancesLiquidity,
	getTryTokensRequset,
} from 'redux/reducers/swap/reducer';
import { addTransferRequest } from 'redux/reducers/wallets/reducer';
import { IExchangeLocationStateWithObject } from '../types';
import { convertExponentialToDecimal } from 'services/utils/convertEcponential';
import { ConvertPercentButtons } from 'ui/ConvertPercentButtons';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	toMaxDecimals,
	checkAfterPoint,
	toDecimalsAfterPoint,
	bigNumberFormater,
	revertBigNumberFormatter,
} from 'services/utils/numbers';
import { ClockSvg, PlusGrayButton } from 'assets/custom-icons';
import AbiTokens from 'config/abi/GUSD.json';
import { NavLink } from 'react-router-dom';
import { getNetworkId } from 'redux/reducers/wallets/selectors';

import { authInitState } from 'redux/reducers/auth/reducer';
import useRefreshToken from 'hooks/useRefreshToken';
import { numberInDecimal, getInitTokenSymbols, getTokenBySymbol } from './utils';

import { ConfirmModal } from './ConfirmModal';
import { IExchangeLocationState } from '../types';
// import { SuccessModal } from './TransactionsModals/succes';

import { TokenInput } from '../TokenInput';
import { ITypeTransaction, IToken } from './type';
import { addTransfer } from 'services/utils/transfer';
import { getGasData } from 'services/utils/getGasData';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';

const SELECTOR_ADD_TRANSFER = '0xf6d8d42a';

const chainId = String(process.env.REACT_APP_CHAIN_ID);

const ROUTER_CONTRACT = String(process.env.REACT_APP_ROUTER_ADDRESS);

declare let window: any;

export const TransferForm: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const assetList = useSelector(getSwapAssetList);
	const tokenList = useSelector(getAddTokenList);
	const walletAddress = useSelector(getAddress);
	const prevWalletAddress = usePrevious(walletAddress?.wallet);
	const network = useSelector(getNetworkId);
	const tokenRefresh = useRefreshToken();
	const { provider, chainId, account } = useWeb3React<Web3Provider>();

	const locationState = location.state as IExchangeLocationStateWithObject | null;
	const [initFromSymbol, initToSymbol] = getInitTokenSymbols(locationState, assetList);

	const [fromTokenSymbol, setFromTokenSymbol] = useState(initFromSymbol);
	const [fromValue, setFromValue] = useState('');
	const [toAddress, setToAddresss] = useState('');
	const [loader, setLoader] = useState(false);
	const [loaderAdd, setLoaderAdd] = useState(false);

	const { walletProvider } = useWeb3ModalProvider();
	const [disableBtn, setDisableBtn] = useState('');

	const fromToken = getTokenBySymbol(tokenList, fromTokenSymbol);
	const { address, chainId: chainWeb3, isConnected } = useWeb3ModalAccount();

	useEffect(() => {
		if (String(network) !== String(chainId)) {
			setOpenModalNetwork(true);
			return;
		}
		setOpenModalNetwork(false);
	}, [network, chainId]);

	// const tryNetwork = network !== chainId;

	const handleFromValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (checkAfterPoint(e.target.value, Number(fromToken?.decimals))) return;
		setFromValue(bigNumberFormater(revertBigNumberFormatter(e.target.value)));
	};

	const [currentPercent, setCurrentPercent] = useState(0.25);
	const [openModal, setOpenModal] = useState(false);
	const [openConfirmModal, setOpenConfirmModal] = useState(false);
	const [openTransactionModal, setOpenTransactionModal] = useState(false);

	const handlerSettings = (e: MouseEvent<HTMLButtonElement>) => {
		setOpenModal(!openModal);
	};

	const checkStatus = (check: boolean) => {
		if (check) {
			setDisableBtn('');
			setOpenConfirmModal(!openConfirmModal);
			return;
		}
		notificationContainer(`Pool is not available at the moment`, 'info');
		setDisableBtn('Pool is not available at the moment');
	};

	useEffect(() => {
		let value1 = Number(revertBigNumberFormatter(fromValue));
		let value2 = Number(fromToken?.balance);

		if (value1 > value2) {
			setDisableBtn(`Insufficient ${fromToken?.symbol} balance`);
		} else {
			setDisableBtn('');
		}
	}, [fromToken, fromValue]);

	const handleMax = (e: MouseEvent<HTMLButtonElement>) => {
		const { name } = e.currentTarget;
		if (name === 'from') {
			setFromValue(
				bigNumberFormater(
					String(toDecimalsAfterPoint(String(fromToken?.balance), Number(fromToken?.decimals))),
				),
			);
			return;
		}
	};

	useEffect(() => {
		if (prevWalletAddress !== walletAddress.wallet) {
			dispatch(clearTokenBalances());
		}

		if (assetList && walletAddress.wallet) {
			dispatch(getTokenBalancesLiquidity('liquidity'));
		}
	}, [walletAddress.wallet, assetList, dispatch]); // eslint-disable-line

	// Reset tokens to initial/available, if not in list

	const disabled = !fromValue || !toAddress || !fromToken;

	useEffect(() => {
		if (fromToken) return;

		const initFromToken = getTokenBySymbol(tokenList, initFromSymbol);

		const fromTokenSet = tokenList;

		let toTokenSet = tokenList;

		const newFromSymbol = initFromToken ? initFromSymbol : fromTokenSet?.[0];

		if (!fromToken && newFromSymbol) {
			setFromTokenSymbol(newFromSymbol);
		}
	}, [tokenList, fromToken, initFromSymbol]);

	const countFrom = (value: number) => {
		setFromValue(
			bigNumberFormater(String(toDecimalsAfterPoint(value, Number(fromToken?.decimals)))),
		);

		// setFromValue(String(value));
	};

	const percentButtonCountValue = (percentValue: number): number => {
		if (!toMaxDecimals(String(fromToken?.balance), 6) || !Number(percentValue)) {
			return 0;
		}

		return Number(fromToken?.balance) * percentValue;
	};

	const handleAddTransform = async () => {
		if (localStorage.accessToken === 'null') {
			dispatch(authInitState());
			return;
		}
		tokenRefresh();

		const connector = window.localStorage.getItem('connectorLocalStorageKey');
		const currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;

		if (!currentProvider) return;

		if (!fromToken) {
			notificationContainer(`Select token`, 'info');
			return;
		}
		if (!toAddress) {
			notificationContainer(`Add wallet address`, 'info');
			return;
		}

		try {
			const connector = window.localStorage.getItem('connectorLocalStorageKey');
			let accounts;
			const currentProvider = connector === 'INJECTED' ? provider?.provider : walletProvider;
			const currentAddress = connector === 'INJECTED' ? account : address;

			if (connector) {
				// accounts = await library?.provider.request({
				// 	method: 'eth_requestAccounts',
				// });
			}

			const amountInWithDecimals = numberInDecimal(
				Number(revertBigNumberFormatter(fromValue)),
				Number(fromToken?.decimals),
			);

			const paramsFee: any = {
				wallet: currentAddress,
				amount: String(amountInWithDecimals),
				selector: SELECTOR_ADD_TRANSFER,
				abi: AbiFee.abi,
				contractAddess: ROUTER_CONTRACT, // Address contract
				provider: currentProvider,
				setLoader,
				connector,
				tokenAddress: String(fromToken?.address),
			};

			const amountFee = await feeChecker(paramsFee); // this is to get the fee from smart contract

			const gasData = await getGasData({ tokenAddress: fromToken?.address });

			if (!gasData) {
				return;
			}

			if (!amountFee) {
				return;
			}

			const sendParams: any = {
				wallet: String(currentAddress),
				abi: AbiTokens.abi,
				to: ROUTER_CONTRACT,
				amount: String(
					noExponent(
						Math.round(
							Number(
								numberInDecimal(
									Number(revertBigNumberFormatter(fromValue)),
									Number(fromToken?.decimals),
								).toString(),
							),
						),
					),
				),
				tokenName: fromToken?.name,
				contractAddess: String(ROUTER_CONTRACT),
				firstType: fromToken?.type === 'coin',
				provider: currentProvider,
				// tokenAddress: String(fromToken?.address),
				firstTokenAddress: String(fromToken?.address),
				toAddress: toAddress,
				fromTokekenAsset: {
					...fromToken,
					fromTokenAmount: String(
						noExponent(
							Math.round(
								Number(
									numberInDecimal(
										Number(revertBigNumberFormatter(fromValue)),
										Number(fromToken?.decimals),
									).toString(),
								),
							),
						),
					),
				},
				type: fromToken?.type === 'coin' ? 'coin' : 'token',
				amountFee,
				gasData,
				// setLoaderAdd,
				connector,
			};

			await addTransfer(sendParams);

			setOpenConfirmModal(false);
		} catch (error: any) {
			notificationContainer(`Error: ${String(error?.message)}`, 'error');
		}
	};

	const swapAssets = useSelector(getSwapAssets);
	const status = useSelector(getTokenStatus);
	// const [openModal, setOpenModal] = useState(false);
	const [openModalNetwork, setOpenModalNetwork] = useState(false);

	const locationLink = location.pathname.split('/')[1];

	// useEffect(() => {
	// 	if (status) {
	// 		setOpenModal(true);
	// 	}
	// }, [status]);

	useEffect(() => {
		if (!swapAssets) {
			dispatch(
				getSwapAssetsRequest({
					per_page: 100,
					is_active: 1,
				}),
			);
		}
	}, [dispatch]); // eslint-disable-line

	const changeAddress = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		setToAddresss(value);
	};

	const disableScroll = useCallback((e: WheelEvent) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	const handleInputFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
		e.target.addEventListener('wheel', disableScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className={`general-section `}>
			<div className="container">
				<div className="inner-block">
					<div className="general-block general-block--center">
						<div className="block-header">
							<h4 className="general-block-title">Transfer</h4>
						</div>

						<div className="block-content">
							<div className="exchange-item">
								<TokenInput
									tokenList={tokenList}
									value={fromToken}
									onChange={(token) => setFromTokenSymbol(token)}
									title="From"
									manage
								/>

								<div className="input input--exchange">
									<label>
										<div className="input-wrapper">
											<input
												className="input-item input-item--exchange input-item--get-info"
												value={noExponent(fromValue)}
												onChange={handleFromValueChange}
												type="text"
												onFocus={handleInputFocus}
												placeholder="0"
											/>
											<button
												onClick={handleMax}
												name="from"
												type="button"
												className="exchange-max-btn"
											>
												MAX
											</button>
										</div>
									</label>
								</div>
							</div>

							<ConvertPercentButtons
								countFrom={countFrom}
								percentButtonCountValue={percentButtonCountValue}
								amount={revertBigNumberFormatter(fromValue)}
								currentPercent={currentPercent}
								setCurrentPercent={setCurrentPercent}
							/>

							<div className="exchange-transfer">
								<p className="exchange-transfer__text">
									Availability: {toMaxDecimals(String(fromToken?.balance || 0), 8)}{' '}
									{fromToken?.symbol}
								</p>
							</div>
							<div className="exchange-item">
								<div className="input input--exchange">
									<label>
										<div className="input-wrapper">
											<input
												type="text"
												onChange={changeAddress}
												className="input-item input-item--transfer input-item--get-info"
												placeholder="address"
												value={toAddress}
											/>
										</div>
									</label>
								</div>
							</div>
						</div>

						<div className="block-footer">
							{prevWalletAddress ? (
								<button
									disabled={!fromValue || !toAddress || !fromToken || !!disableBtn}
									onClick={handleAddTransform}
									type="button"
									className="button button--big-height button--full-width"
								>
									{fromValue ? (
										<span>{!disableBtn ? 'Send transaction' : disableBtn}</span>
									) : (
										'Enter an amount'
									)}
								</button>
							) : (
								<ConnectWalletButton />
							)}
						</div>
						{/* <SettingsModal openModal={openModal} setOpenModal={setOpenModal} slipping={slipping} />
						<ConfirmModal
							openModal={openConfirmModal}
							setOpenModal={setOpenConfirmModal}
							itemIn={fromToken}
							fromValue={fromValue}
							itemOut={toToken}
							toValue={toValue}
							displayedRate={displayedRate ? convertExponentialToDecimal(displayedRate) : 0}
							aproveSwap={handleSwap}
							updatePrice={updatePrice}
							slipping={slipping}
							loader={loader}
						/>
						<SuccessModal
							openModal={openTransactionModal}
							setOpenModal={setOpenTransactionModal}
							loader={loader}
							hash={hash}
						/> */}
					</div>
				</div>
			</div>
		</section>
	);
};

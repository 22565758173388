/* eslint-disable */
import { Connector } from '@web3-react/types';
import { useEffect } from 'react';

import { ConnectionType, getConnection } from 'services/utils/connections';

async function connect(connector: Connector) {
	try {
		if (connector.connectEagerly) {
			await connector.connectEagerly();
		} else {
			await connector.activate();
		}
	} catch (error) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		console.debug(`web3-react eager connection error: ${error}`);
	}
}
export const useEagerlyConnect = () => {
	useEffect(() => {
		// connect(getConnection(ConnectionType.INJECTED).connector);
		connect(getConnection(ConnectionType.NETWORK).connector);
		// connect(getConnection(ConnectionType.WALLET_CONNECT).connector);
		connect(getConnection(ConnectionType.GNOSIS_SAFE).connector);
	}, []);
};

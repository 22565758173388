export { ReactComponent as CrossIcon } from './cross.svg';
export { ReactComponent as GearIcon } from './gear.svg';
export { ReactComponent as MagnifierIcon } from './magnifier.svg';
export { ReactComponent as RefreshIcon } from './refresh.svg';
export { ReactComponent as ReverseIcon } from './reverse.svg';
export { ReactComponent as TriangularArrowIcon } from './triangular-arrow.svg';
export { ReactComponent as QuestionIcon } from './question.svg';
export { ReactComponent as OpenEye } from './eye-icon/open.svg';
export { ReactComponent as CloseEye } from './eye-icon/close.svg';
export { ReactComponent as SwapIcon } from './type-icon/swap.svg';
export { ReactComponent as CloseButtonIcon } from './buttons-icon/close.svg';
export { ReactComponent as ArrowBack } from './buttons-icon/arrow-back.svg';
export { ReactComponent as PlusButton } from './buttons-icon/plus.svg';
export { ReactComponent as PlusGrayButton } from './buttons-icon/plus-gray.svg';
export { ReactComponent as SearchIcon } from './buttons-icon/search.svg';
export { ReactComponent as ArrowLeft } from './arrows/arrowLeft.svg';
export { ReactComponent as ArrowRight } from './arrows/arrowRight.svg';
export { ReactComponent as TransactionIcon } from './type-icon/transaction.svg';
export { ReactComponent as ArrowUp } from './arrows/arrowUp.svg';
export { ReactComponent as TooltipNotation } from './tooltip-notation.svg';
export { ReactComponent as TooltipInformation } from './tooltip-info.svg';
export { ReactComponent as CopySvg } from './buttons-icon/copy.svg';
export { ReactComponent as ClockSvg } from './clock.svg';
export { ReactComponent as ClockOrangeSvg } from './clock_orange.svg';
export { ReactComponent as CheckCircleSvg } from './check-circle.svg';
export { ReactComponent as AttentionY } from './notifications/attention.svg';
export { ReactComponent as AttentionRed } from './notifications/attentionRed.svg';
export { ReactComponent as ArrowDown } from './arrows/arrow-down.svg';
export { ReactComponent as ArrowSelect } from './arrows/arrow-select.svg';
export { ReactComponent as CubeButton } from './buttons-icon/button-header/cube-icon.svg';
export { ReactComponent as EchangeButton } from './buttons-icon/button-header/exchange-icon.svg';
export { ReactComponent as WalletButton } from './buttons-icon/button-header/wallet-icon.svg';
export { ReactComponent as BridgeButton } from './buttons-icon/button-header/bridge-icon.svg';
export { ReactComponent as OverviewSt } from './NavIcon/overview-icon.svg';
export { ReactComponent as DividentsStaking } from './NavIcon/dividentsStaking-icon.svg';
export { ReactComponent as StakingSt } from './NavIcon/staking-icon.svg';
export { ReactComponent as InfinitySt } from './NavIcon/infinity-icon.svg';
export { ReactComponent as DividentSt } from './NavIcon/dividents-icon.svg';
export { ReactComponent as IconCoinGato } from './icon-coin-gato.svg';
export { ReactComponent as Scan } from './scan.svg';
